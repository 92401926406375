/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://ataskaita.kartais.lt/2014/09/22/uz-rugsejo-14-rugsejo-22-2014.html"
  }, "Kadais rašiau"), "\napie norą parašyti ", React.createElement(_components.a, {
    href: "http://interakcijos.lt"
  }, "interakcijoms"), " straipsnį. Be\njokio tikslo, tiesiog iš solidarumo – norėčiau, suprask, padėti. Ir, man\nrodos, pagaliau radau temą, kurią norėčiau panagrinėti: ", React.createElement(_components.a, {
    href: "http://designopen.org/"
  }, "Design\nOpen"), ". Tai yra iniciatyva dizaino industrijai\ndalintis savo procesais, mintimis ir klaidomis taip, kaip tai daro\n", React.createElement(_components.a, {
    href: "http://en.wikipedia.org/wiki/Free_and_open-source_software"
  }, "FOSS"), " ir\n", React.createElement(_components.a, {
    href: "http://en.wikipedia.org/wiki/Open_source_hardware"
  }, "Open Hardware"), "\nkūrėjai."), "\n", React.createElement(_components.p, null, "Jau kuris laikas vis pagaunu save karts nuo karto atsidarant ", React.createElement(_components.a, {
    href: "https://gds.blog.gov.uk/2011/07/29/alpha-gov-uk-wrap-up/"
  }, "Jungtinės\nKaralystės valdžios\nvartus"), " ir\nsvajojant kaip vieną dieną ir aš dirbsiu prie tokio lygio ir kokybės\nprojekto. Būtų labai smagu apjungti tas dvi temas ir papildyti jas\nbandymu pasidomėti su lietuviškos valdžios atstovais (tebūnie tai\nžmonės, kurie budi prie telefonų) apie galimybę tobulinti jų\ntinklalapius. Nepriklausomai, be atlygio ir atvirai pasauliui. Taip,\nkaip tai darytų Design Open chebra."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Spoksant į tuščią balkono sieną Viltei iš jo išnešus pomidorus, kilo\nmintis pačiam pabandyti užauginti ", React.createElement(_components.a, {
    href: "http://www.bltrobotics.com/farm.php"
  }, "gyvą pomidorų (ar kokių prieskonių)\nsieną"), " su automatiniu laistymu ir,\ngalbūt, saulės šviesa. Kaip sako, roges ruošk vasarą, o apie ", React.createElement(_components.a, {
    href: "http://hackersphere007.blogspot.com/2014/06/the-living-wall.html"
  }, "gyvą\nsieną"), "\nmąstyk spalio mėnesį. ", React.createElement(_components.a, {
    href: "http://gardenbot.org/about/"
  }, "GardenBot"), " turi\ndaugiau nuorodų."), "\n", React.createElement(_components.p, null, "Smagus daiktas turint pinigų – ", React.createElement(_components.a, {
    href: "http://www.adafruit.com/products/1722"
  }, "fotosintezės\nanalizatorius"), " (gerą žodį\nsugalvojau, ką?)."), "\n", React.createElement(_components.p, null, "O iki kol galėsiu pasistatyt šiltnamį, su dirbtiniu saulės apšvietimu –\n", React.createElement(_components.a, {
    href: "http://blog.blinkenlight.net/"
  }, "blinkenlight"), " turi milijoną idėjų\nmokymuisi."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Worst business idea of the week: generative programmable posters – you\ncode, we print. Nors, įtariu, galėtų ir suveikti."), "\n", React.createElement(_components.p, null, "Iš posterių minties – pardavinėti specialius makerspace poster’ius\nžmonėms, norintiems mus paremti. Ir mums kapeika, ir jiems plakatas.\nNors ir su rėmais, kad juos kur."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Dabar bandau darbintis. Buvau keliose vietose, pasikalbėjom, bet visose\njose vis išlįsdavo toks sakinys: „…, bet pas mus taip nebūna“. Ir aš\nniekaip nesuprantu kodėl. Man belieka daryti išvadą, jog žmogus, kuris\ntaip sako, yra dirbęs tokioje (nepageidaujamoje) aplinkoje ir dėl to jam\ngėda, todėl kaip ginybos mechanizmą naudoja „bet pas mus tai ne taip“,\narba „pas juos“ yra būtent taip, kaip jie sako, kad nėra, ir dėl to\njiems gėda pripažinti, kad jie apie tai žino (nes nieko dėl to\nnekeičia)."), "\n", React.createElement(_components.p, null, "Ir kai pitch’inat, chebra, savo kompaniją potencialiam darbuotojui, nu\nnenaudokit perk’ų kaip jie ką nors keistų. Jie yra tik add-on’as, o ne\nsprendžiamasis faktorius. Kai juos vardinat, kaip tai būtų žiauriai\ndideli pliusai jums, man belieka galvoti, kad visa kita (suprask,\ndarbas, kurį reikia padaryti po to kai suvalgai visus maistus skiriamus\ntau ir išgėrus visą ofiso alų) – gerokai nekvepia. Tai tik reiškia, kad\njūs negalvojat apie savo darbuotojus, kurie alaus negeria, ar į\nkompanijos vakarienes negali eiti, nes jie ir taip jau gyvenimus turi.\nSuprantu, kad tokie perk’ai yra kietai twenty-odd-year-olds’am, bet ne\nvisiems kitiems."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
